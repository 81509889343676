.Reasons{
  
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}
.left-r{
    flex: 1 1 ;/*  de hai ben bang nhau */
    display: grid;
    grid-template-columns: repeat(3, 1fr); /*chia lam 3 cot, 1fr có nghĩa là mỗi cột sẽ chiếm 1 phần của không gian còn lại trong grid.*/
    gap: 1rem; 
    grid-auto-rows: 1fr; 
}


.left-r>img{
    object-fit: cover;
}
.left-r>:nth-child(1){
    /* width: 12rem; */
    width: 100%;
    grid-row: 1/3;
    height: 28rem;
}
.left-r>:nth-child(2){
    /* width: auto; */
    width: 100%;
    height: 16rem;
    grid-column: 2/4;
}
.left-r>:nth-child(3){
    /* width: 14rem; */
    width: 100%;
    height: 11rem; /*tu them*/
    grid-column: 2/3;
    grid-row: 2;
}
.left-r>:nth-child(4){
    /* width: 10rem; */
    width: 100%;
    height: 11rem; /* tu them*/
    grid-row: 2;
    grid-column: 3/4;
}


.right-r{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.right-r>span{
    font-weight: bold;
    color: var(--orange);
}
.right-r>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
}
.details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
    line-height: 30px;
}
.details-r>div>img{
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex;
    gap: 1rem;
}
.partners>img{
    width: 2.5rem;
}

@media screen and (max-width:768px) {
    .Reasons{
       flex-direction: column; 
    }
}